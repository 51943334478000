<template>
  <div class="card">
    <div class="card-header pb-75" style="padding-right: 0">
      <div class="w-100 row justify-content-between align-items-center">
        <div
          class="d-flex flex-column align-items-center justify-content-start col-sm-12"
        >
          <h3 class="mb-25 font-weight-bolder">
            Customize Your Subscription Plan
          </h3>
          <p class="mb-50">
            Complete the following steps to customize the subscription.
          </p>
        </div>
      </div>
    </div>
    <div class="card-body">
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <form-wizard
          color="#7367f0"
          error-color="#ea5455"
          step-size="xs"
          class="w-100 mx-0"
          ref="wizard"
          @on-complete="handleSubmitClick"
          :hide-buttons="false"
        >
          <div slot="title" class="p-0 m-0"></div>

          <tab-content
            title="Basic"
            :selected="true"
            :before-change="() => validateStep('step1')"
          >
            <validation-observer ref="step1">
              <div
                class="d-flex flex-column align-items-start justify-content-start w-100 h-auto mt-3"
              >
                <div class="w-100 h-auto rounded border-light px-1 py-50 mb-3">
                  <label class="mb-1"
                    ><h4 class="mb-0">Effective Duration</h4></label
                  >
                  <div
                    class="w-100 d-flex align-items-center justify-content-between"
                  >
                    <b-form-group label="From Date" style="width: 45%">
                      <validation-provider
                        #default="{ errors }"
                        name="From Date"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-datepicker
                          @input="updateToDateMinDate"
                          v-model="formData.effective_from"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            weekday: 'short',
                          }"
                          locale="en"
                          :min="minDueDate"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="To Date" style="width: 45%">
                      <validation-provider
                        #default="{ errors }"
                        name="To Date"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-datepicker
                          v-model="formData.effective_to"
                          :disabled="formData.effective_from == null"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            weekday: 'short',
                          }"
                          locale="en"
                          :min="minDueDateForTo"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>

                <div
                  class="d-flex flex-column align-items-start justify-content-start w-100"
                >
                  <feature-selection-list
                    @on-feature-select="handleFeatureSelected"
                    @on-feature-remove="handleFeatureRemoveClick"
                    :selectedFeatures="selectedFeatures"
                    title="Features"
                    description="Choose any of the following features."
                  />
                </div>
              </div>
            </validation-observer>
          </tab-content>

          <tab-content title="Finish">
            <div ref="step2" class="h-100 w-100 mt-3">
              <selected-features-list
                :selectedFeatures="selectedFeatures"
                @on-selectedfeature-remove="handleFeatureRemoveClick"
                :updateFeature="handleUpdateFeature"
                @on-selectedFeaturesChange="handleSelectedFeaturesChange"
              />
            </div>
          </tab-content>

          <template slot="custom-buttons-right" slot-scope="props">
            <b-button
              v-if="props.activeTabIndex != 1"
              size="sm"
              variant="success"
              @click="handleSubmitClick"
              :disabled="selectedFeatures.length == 0"
              ><feather-icon icon="SaveIcon" class="mr-50" /><span
                class="align-middle"
                >Save & Exit</span
              ></b-button
            >
          </template>

          <b-button size="sm" variant="primary" slot="next"
            ><span class="align-middle">Continue</span
            ><feather-icon icon="ArrowRightIcon" class="ml-50"
          /></b-button>

          <b-button size="sm" variant="primary" slot="prev"
            ><feather-icon icon="ArrowLeftIcon" class="mr-50" /><span
              class="align-middle"
              >Back</span
            ></b-button
          >
          <b-button
            size="sm"
            variant="success"
            slot="finish"
            :disabled="selectedFeatures.length == 0"
            ><feather-icon icon="CheckIcon" class="mr-50" /><span
              class="align-middle"
              >Finish</span
            ></b-button
          >
        </form-wizard>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import ResponseMixins from "../../mixins/ResponseMixins";
import SubscriptionMixins from "../../mixins/SubscriptionMixins";
import { FormWizard, TabContent } from "vue-form-wizard";
import {
  BOverlay,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BForm,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
  BTab,
  BTabs,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import FeatureSelectionList from "../plans/components/FeatureSelectionList.vue";
import SelectedFeaturesList from "../plans/components/SelectedFeaturesList.vue";

export default {
  components: {
    FormWizard,
    TabContent,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BForm,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    BTab,
    BTabs,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    required,
    FeatureSelectionList,
    SelectedFeaturesList,
  },

  mixins: [ResponseMixins, SubscriptionMixins],

  data() {
    return {
      subscription: null,
      showOverlay: false,

      minDueDate: null,
      minDueDateForTo: null,
      selectedFeatures: [],

      formData: {
        effective_from: null,
        effective_to: null,
        features: [],
      },
    };
  },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$refs.wizard.activateAll();
      this.getSubscriptionAndSetData();
    },

    validateStep(name) {
      if (name == "step1") {
        if (this.$refs[name].validate() === false) {
          this.showToast("Complete the fields to continue", "warning");
          return false;
        } else if (this.selectedFeatures.length === 0) {
          this.showToast("Select atleast one feature to continue.", "warning");
          return false;
        } else {
          return true;
        }
      }
    },

    handleSubmitClick() {
      if (this.validateStep("step1")) {
        this.formData.features = this.selectedFeatures;
        this.updateSubscriptionAndSetData();
      }
    },

    updateSubscriptionAndSetData() {
      this.showOverlay = true;
      this.updateSubscription(this.$route.params.id, this.formData)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    updateToDateMinDate() {
      this.$set(this.formData, "effective_to", null);
      const fromDate = new Date(this.formData.effective_from);
      fromDate.setDate(fromDate.getDate() + 1);
      this.minDueDateForTo = fromDate.toISOString().split("T")[0];
    },

    setMinDueDate() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      this.minDueDate = date;
    },

    getSubscriptionAndSetData() {
      this.showOverlay = true;
      this.getSubscription(this.$route.params.id)
        .then((res) => {
          console.log(res);
          this.subscription = res.data.data;
          this.formData.effective_from = res.data.data.effective_from;
          this.updateToDateMinDate();
          this.formData.effective_to = res.data.data.effective_to;

          if (res.data.data.features && res.data.data.features != null) {
            this.selectedFeatures = res.data.data.features.map((feature) => {
              let newFeature = {};

              if (
                feature.meta &&
                feature.meta != null &&
                feature.meta.selected_field_values &&
                feature.meta.selected_field_values != null &&
                feature.meta.selected_field_values.length > 0
              ) {
                feature["selected_field_values"] =
                  feature.meta.selected_field_values;
              }

              if (feature.unit == "count") {
                newFeature = {
                  ...feature,
                  inputValue: feature.quantity,
                  _id: feature._id["$oid"],
                };
              } else if (feature.unit == "credit") {
                newFeature = {
                  ...feature,
                  inputValue: feature.tariff,
                  _id: feature._id["$oid"],
                };
              } else if (feature.unit == "boolean") {
                newFeature = {
                  ...feature,
                  inputValue: feature.quantity,
                  _id: feature._id["$oid"],
                };
              }
              return newFeature;
            });
          }

          // this.selectedFeatures = res.data.data.features.map((feature) => {
          //   let newFeature = {};

          //   if (feature.unit == "count") {
          //     newFeature = {
          //       ...feature,
          //       inputValue: feature.quantity,
          //       _id: feature._id["$oid"],
          //     };
          //   } else if (feature.unit == "credit") {
          //     newFeature = {
          //       ...feature,
          //       inputValue: feature.tariff,
          //       _id: feature._id["$oid"],
          //     };
          //   } else if (feature.unit == "boolean") {
          //     newFeature = {
          //       ...feature,
          //       inputValue: feature.quantity,
          //       _id: feature._id["$oid"],
          //     };
          //   }
          //   return newFeature;
          // });
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleSelectedFeaturesChange(newFeatures) {
      this.selectedFeatures = newFeatures;
    },

    handleFeatureSelected(feature) {
      // console.log(feature)
      this.selectedFeatures.push(feature);
    },

    handleFeatureRemoveClick(id) {
      let newFeatures = [...this.selectedFeatures];
      newFeatures = newFeatures.filter((feature) => feature._id != id);
      this.selectedFeatures = newFeatures;
      if (newFeatures.length == 0) {
      }
    },

    handleUpdateFeature(feature) {
      const featureIndex = this.selectedFeatures.findIndex(
        (f) => f._id == feature._id
      );
      const newSelected = [...this.selectedFeatures];
      newSelected[featureIndex] = feature;
      this.selectedFeatures = newSelected;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
// Overrides user variable
@import "~@core/scss/base/components/include";

.vue-form-wizard {
  .wizard-header {
    padding: 0;
  }
  .wizard-navigation {
    .wizard-tab-content {
      margin: 0;
      padding: 0;
    }
    .wizard-icon-circle {
      border: 2px solid $primary;
      .wizard-icon {
        color: $primary;
      }
    }

    .stepTitle {
      color: $body-color;
    }
  }
}

body {
  &.dark-layout {
    .vue-form-wizard {
      .stepTitle {
        color: $theme-dark-body-color;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
